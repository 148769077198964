
/*------------------------------------*\
  #Line-breaks
\*------------------------------------*/

// Make br not count on mobile breakpoint
.sm-md-only\:auto-line-breaks {
    @include respond-to(sm-md-only) {
        br {
            display: none; // –> this will break if html is minified
        }
    }
}
.sm-lg-only\:auto-line-breaks {
    @include respond-to(sm-lg-only) {
        br {
            display: none; // –> this will break if html is minified
        }
    }
}
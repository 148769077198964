/*------------------------------------*\
  #QUOTES
\*------------------------------------*/

/**
 * Basic styling for quoted text.
 */

q {
    // quotes: "‘" "’";
    quotes: none;
    @include text-style('700');
    display: inline-block;
    text-indent: var(--text-indent);

    @include respond-to(md) {
        @include text-style('1000');
    }

    /*
    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }*/

}

blockquote {
    quotes: none;

    p + p {
        padding-left: 2ex;
    }
}

/*------------------------------------*\
  #ICONS
\*------------------------------------*/

/// Icon Definitions
/// @group Icons
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    position: relative;

    svg {
        @include cover-box();
        stroke: currentColor;
        stroke-width: map-get($stroke-width, s);
        width: 100%;
        height: 100%;
    }
}

// Path animation
// Animate path dashes to create illusion of path animation
// https://css-tricks.com/svg-line-animation-works/
// Using intersection observer to animated icons
.icon--animated {
    --animation-duration: 0.8s;

    path {
        stroke-dasharray: var(--path-length);
        stroke-dashoffset: var(--path-length);

        .intersect-infullview--before & {
            animation: path-hide var(--animation-duration) $trans-func--ease-in-out forwards;
        }

        .intersect-inview--full & {
            animation: path var(--animation-duration) $trans-func--ease-in-out var(--animation-delay) forwards;
        }
    }
}

.icon--circle-1 {
    --path-length: 650;
}

.icon--line-1 {
    --path-length: 737;
}

.icon--line-2 {
    --path-length: 247;
}

.icon--connect-1 {
    --path-length: 280;
    --animation-duration: 0.45s;
    height: 12rem;
    width: 4rem;
}

.icon--connect-2 {
    --path-length: 305;
    --animation-duration: 0.45s;
    height: 11rem;
    width: 4rem;
}


// default stroke icons
// .icon--schekorr-etc {
//     svg {
//         fill: transparent;
//         stroke: currentColor;
//         stroke-width: $stroke-width;
//     }
// }

// Inline Icons
// See: https://github.com/TrySound/postcss-inline-svg
// Definition: @svg-load icon--din-[name] url(../icons/din-[name].svg)
// Usage: background-image: svg-inline(icon--din-[name]);
// ---------------------------------------------------
//
// @svg-load icon--circle-1 url(../icons/circle-1.svg) {
//     stroke: map-get($text-colors, highlight);
//     stroke-width: map-get($stroke-width, m);
// }

/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

// Default button
//
// 1. Allow us to style box model properties.
// 2. Line different sized buttons up a little nicer.
// 3. Make buttons inherit font styles (often necessary when styling `input`s as buttons).
// 4. Reset/normalize some styles.
// 5. Force all button-styled elements to appear clickable.
// 6. Remove default appearance in some native occasions
// 7. Make buttons inherit font colors (often necessary when styling `a`s as buttons).
//

$_btn-h: 2rem;
$_btn-p: 1rem;

.btn {
    display: inline-block; // [1]
    vertical-align: middle; // [2]
    font: inherit; // [3]
    text-align: center; // [4]
    cursor: pointer; // [5]
    color: inherit; // [6]
    outline: none; // [4]
    border: none; // [4]
    background: none; // [4]
    appearance: none; // [7]
    white-space: nowrap; // [8]

    @include text-style('400');
    background-color: var(--bg-highlight);
    padding: 0 $_btn-p;
    line-height: $_btn-h;
    border-radius: $_btn-h;
}

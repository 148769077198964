/*------------------------------------*\
  #Intro
\*------------------------------------*/

.intro__header {
    padding: var(--body-p);
    margin-top: var(--text-box-align);
    padding-bottom: 8rem;
    min-height: 75vh;

    h1 {
        max-width: 36ex;
    }
}

.intro__body {
    display: flex;
    flex-direction: column;
    padding-left: var(--body-p);
    position: relative;

    @include respond-to(lg) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.intro__img {
    margin-left: var(--text-indent);

    // align media box to the right
    .media-box {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @include respond-to(lg) {
        width: calc(50% + var(--body-p) / 2);
    }
}

.intro__cta {
    --animation-delay: 0.2s; // wait some time before animation triggers here

    position: absolute;
    top: -6.5rem;
    right: var(--body-p); // fix body overflow on mobile

    @include respond-to(md) {
        right: 5rem;
        top: -9.5rem;
    }
}

.intro__cta-2 {
    --animation-delay: 0.4s; // wait some time before animation triggers here
}

.intro__text {
    margin-top: var(--size-1000);
    margin-left: var(--text-indent);
    margin-right: var(--body-p);

    @include respond-to(lg) {
        margin-top: calc(25vh + 4rem);
    }
}


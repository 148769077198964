/*------------------------------------*\
  #Footer
\*------------------------------------*/

.footer {
    margin-top: var(--size-1000);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: var(--body-p);

    @include respond-to(lg) {
        margin-top: var(--size-1400);
    }
}

.footer__body {
    margin-top: var(--text-box-align);

    @include respond-to(lg) {
        flex-direction: row;
        width: 50%;
    }
}

.footer__box {
    @include respond-to(md) {
        @include text-style('600');
        --flow-space: 0;

        p {
            white-space: nowrap;
        }
    }

    @include respond-to(lg) {
        margin-right: 3rem;
    }
}

.footer__footer {
    margin-top: auto;
    justify-content: flex-end;
    @include text-style('300');
}

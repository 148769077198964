/*------------------------------------*\
  #article
\*------------------------------------*/

.article {
    min-height: 100vh;

    @include respond-to(lg) {
        display: flex;
        align-items: flex-start;
    }
}

.article__head {
    padding: var(--body-p);
    margin-top: var(--text-box-align);
    display: flex;
    justify-content: space-between;

    @include respond-to(lg) {
        width: 50%;
        position: sticky;
        top: var(--text-box-align);
        align-items: center;

        > * {
            width: 50%;
        }
    }
}

.article__body {
    padding: var(--body-p);

    @include respond-to(lg) {
        padding-right: 4rem;
        margin-top: var(--text-box-align);
        width: 50%;
    }
}
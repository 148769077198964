/*------------------------------------*\
  #LOGO
\*------------------------------------*/

.logo {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    padding: var(--body-p);
    z-index: map-get($z-index, logo);
    @include text-color('highlight');
    transition: transform $trans-time--s $trans-func--ease-in-out,
                opacity $trans-time--s $trans-func--ease-in-out;
    opacity: 0;
    transform: translate3d(0, 50%, 0) scaleY(100);
    visibility: hidden; // decrease size of clickarea

    .is-scrolled-some & {
        opacity: 1;
        transform: none;
    }

    @include respond-to(lg) {
        opacity: 1;
        transform: none;
    }
}

.logo__title {
    // logo scaling
    transform-origin: left bottom;
    font-size: calc(var(--size-400) * var(--logo-scale));
    transform: scale(calc(1 / var(--logo-scale)));
    transition: transform $trans-time--xs $trans-func--ease-in-out,
                letter-spacing $trans-time--xs $trans-func--ease-in-out;
    display: block;
    visibility: visible; // decrease size of clickarea

    .has-contact & {
        transform: translate3d(0,0.3ex,0);
        letter-spacing: -0.055em;
        animation: logo 0.25s $trans-func--logo backwards;
    }

    @include respond-to(md) {
        @include text-style('600');
        font-size: calc(var(--size-600) * var(--logo-scale));
    }
}

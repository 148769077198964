/*------------------------------------*\
  #Main Nav
\*------------------------------------*/

.nav {
    display: none;
    z-index: map-get($z-index, nav);

    @include respond-to(md) {
        @include text-style('600');
    }

    @include respond-to(lg) {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        padding: var(--body-p) var(--body-p) 0 0;
        margin-top: var(--text-box-align);

        ul {
            justify-content: space-between;
        }

        .is-scrolled-some & {
            position: fixed;
        }

        @include no-hover() {
            position: fixed;
        }
    }
}

.nav__item {
    @include respond-to(lg) {
        @include no-touch() {
            .is-scrolled-some & {
                opacity: 0;
                // transform: scaleY(1.4) scaleX(0.95);
                transform: scaleY(0.7) scaleX(1.05);
                pointer-events: none;
            }

            .is-scrolled-decent & {
                transition: opacity $trans-time--xs $trans-func--default,
                            transform $trans-time--xs $trans-func--ease-in-out;
            }

            .show-new-section & {
                a {
                    pointer-events: none;
                }
            }
        }

        .nav:hover & {
            opacity: 1;
            transform: none;
            pointer-events: all;
            @include transition-delay(0.05s, 0s, 4);
        }
    }
}

.has-challenges .nav__item--challenges,
.has-services .nav__item--services,
.has-about .nav__item--about,
.has-contact .nav__item--contact {
    opacity: 1;
    transform: none;
    pointer-events: all;
    @include text-color('highlight');
    // animation: nav-show $trans-time--s $trans-func--ease-out forwards;
}

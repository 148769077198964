/*------------------------------------*\
  #challenges
\*------------------------------------*/

.challenges {
    @include respond-to(lg) {
        padding-top: var(--size-1000); // position for anchor
    }
}

.challenges__header,
.challenges__text {
    margin-right: var(--body-p);
    margin-left: calc(var(--text-indent) + var(--body-p));

    @include respond-to(lg) {
        margin-left: 50%;
    }
}


.challenges__header {
    --measure: 36ex;
}

.challenges__cta {
    margin-left: 7rem;

    @include respond-to(md) {
        margin-left: 35%;
    }

    @include respond-to(lg) {
        margin-left: 62%;
    }
}


/*------------------------------------*\
  #about
\*------------------------------------*/

.about {
    padding-right: var(--body-p);
    padding-bottom: var(--size-1000);
    display: flex;
    flex-direction: column;

    @include respond-to(lg) {
        padding-left: var(--body-p);
        padding-top: var(--size-1400); // position for anchor
        padding-right: 0;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.about__img {
    margin-right: var(--text-indent);

    // align media box to the left
    .media-box {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    @include respond-to(lg) {
        margin-top: var(--size-1400);
        margin-right: 0;
        width: calc(50% + var(--body-p) / 2);

        // align media box to the right
        .media-box {
            border-top-left-radius: var(--border-radius-s);
            border-bottom-left-radius: var(--border-radius-s);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.about__text {
    margin-left: calc(var(--text-indent) + var(--body-p));
    margin-right: var(--body-p);
    margin-top: var(--size-1000);

    @include respond-to(lg) {
        margin-top: 0;
    }
}


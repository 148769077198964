/*------------------------------------*\
  #MEDIA-BOX
\*------------------------------------*/

/**
 * Provide a media container in order to display media (usually images)
 * cropped to certain ratios and provide a placeholder box while images
 * are still loading
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 * 3. Show a background style before lazy images are shown
 */

// media box containing images or videos
// by default aspect ratio is defined by content
.media-box {
    position: relative; // [1]
    overflow: hidden; // [2]
    @include border-radius();

    img,
    video {
        width: 100%;
        transition: opacity $trans-time--xs $trans-func--default,
                    transform $trans-time--m $trans-func--ease-out;

        // Add hover style on a group hover
        @include hover('a') {
            transform: scale(var(--img-scale));
        }
    }
}


// media boxes with aspect ratios have a specific ratio
.media-box--portrait {
    @include aspect-ratio($portrait-ratio);

    img,
    video {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
    }
}
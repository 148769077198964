/*------------------------------------*\
  #SERVICES
\*------------------------------------*/

.services {
    color: var(--text-highlight);
    padding: var(--body-p);

    @include respond-to(md) {
        margin-left: var(--text-indent);
    }

    @include respond-to(lg) {
        padding-top: var(--size-800); // position for anchor
    }
}

.services__item {
    @include text-style('600');
    margin: 0 0 -1rem 0; // show descenders
    overflow: hidden;
    @include transition-delay(0.06s, 0s, 20, false, 'div');
    text-indent: calc(var(--text-indent) * -1);
    padding-left: var(--text-indent);

    div {
        padding: 0 0 1rem 0; // show descenders
        transition: transform 0.4s $trans-func--ease-out,
                    opacity 0.2s $trans-func--ease-out;
        transform-origin: top left;
    }

    &:not(.intersect-inview):not(.intersect-infullview--before) {
        div {
            transform: translate3d(0, 4em, 0) scaleY(50);
            opacity: 0;
        }
    }

    span {
        @include text-style('100');
        display: inline-block;
        max-width: 13ex;
        margin-left: 2ex;
        margin-right: -5ex;
    }

    @include respond-to(md) {
        @include text-style('1000');

        span {
            @include text-style('400');
        }
    }

    @include respond-to(lg) {
        @include text-style('1200');

        span {
            @include text-style('600');
            transform: translateY(-0.1ex);
        }
    }
}


/*------------------------------------*\
  #TAGS
\*------------------------------------*/

.tag {
    @include text-style('100');
    padding: 0 var(--tag-p);
    line-height: var(--tag-h);
    border-radius: var(--tag-h);
    color: var(--text-tag);
    border: 1px solid var(--border-default);

    @include respond-to(lg) {
        @include text-style('300');
    }
}

/*------------------------------------*\
  #Slider

  CSS only slider inspired by
  https://codepen.io/Schepp/pen/WNbQByE
\*------------------------------------*/

.slider {
    position: relative;
    // outline: 1px solid rgba(#f99, 1);
}

.slider__viewport {
    max-width: 100%;
    padding: var(--slider-p) 0;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    // scroll-snap-type: x mandatory; // Scrollsnaping not working properly with parallax
    perspective: 100px;

    @include respond-to(md) {
        $_extend: 8rem;
        overflow: hidden;
        margin-top: $_extend * -1;
        margin-bottom: $_extend * -1;
        padding-top: calc(var(--slider-p) + #{$_extend});
        padding-bottom: calc(var(--slider-p) + #{$_extend});
        perspective: none;
    }
}

.slider__slide {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    pointer-events: none;
    // scroll-snap-align: center; // Scrollsnaping not working properly with parallax
    display: flex;
    align-items: center;
    justify-content: center;
    transform: var(--parallax, translateZ(0) scale(1));

    // Add parallax
    &:nth-child(even) {
        --parallax: translateZ(10px) scale(0.9);
    }

    @include hover('', '.is-active') {
        z-index: 1;
    }

    &:not(:first-child) {
        margin-left: calc(-50% + var(--card-w) / 2 - var(--card-offset-x) - var(--card-flash));
    }

    // placing cards
    // #1
    &:nth-child(7n+1) {
        --card-offset-y: -2rem;
        --card-offset-x: 0.1rem;
    }

    // #2
    &:nth-child(7n+2) {
        --card-offset-y: 0.7rem;
        --card-offset-x: 1.5rem;
        --card-flash: 5rem;
    }

    // #3
    &:nth-child(7n+3) {
        --card-offset-y: -1.5rem;
        --card-offset-x: 0.1rem;
        --card-flash: 2.4rem;
    }

    // #4
    &:nth-child(7n+4) {
        --card-offset-y: 2.2rem;
        --card-offset-x: 4.4rem;
        --card-flash: 3.6rem;
    }

    // #5
    &:nth-child(7n+5) {
        --card-offset-y: -0.1rem;
        --card-offset-x: 0.1rem;
        --card-flash: 0.1rem;
    }

    // #6
    &:nth-child(7n+6) {
        --card-offset-y: -2rem;
        --card-offset-x: 7.6rem;
        --card-flash: 3.6rem;
    }

    // #7
    &:nth-child(7n+7) {
        --card-offset-y: 1.4rem;
        --card-offset-x: -0.2rem;
        --card-flash: -4rem;
    }

    @include respond-to(sm-only) {
        &:nth-child(4) {
            .card__text {
                margin-top: 0.2rem;
            }
        }
    }

    @include respond-to(md) {
        transform: none;
        transition: transform $trans-time--s $trans-func--ease-out,
                    opacity $trans-time--s $trans-func--ease-out;
        @include transition-delay(0.15s, 1s);

        .js-intersect:not(.intersect-inview):not(.intersect-infullview--before) & {
            transform: scale(1.2) rotate(12deg);
            opacity: 0;
            transition-delay: 0s;
        }

        &:not(:first-child) {
            margin-left: -100%; // place all on top of each other by default
        }

        // placing cards
        // #1
        &:nth-child(7n+1) {
            --card-offset-y: 2rem;
            --card-offset-x: -10rem;
        }

        // #2
        &:nth-child(7n+2) {
            --card-offset-y: -5rem;
            --card-offset-x: 4rem;
        }

        // #3
        &:nth-child(7n+3) {
            --card-offset-y: -2rem;
            --card-offset-x: -4rem;
        }

        // #4
        &:nth-child(7n+4) {
            --card-offset-y: 4rem;
            --card-offset-x: 6rem;
        }

        // #5
        &:nth-child(7n+5) {
            --card-offset-y: 6rem;
            --card-offset-x: -1rem;
        }

        // #6
        &:nth-child(7n+6) {
            --card-offset-y: 0.1rem;
            --card-offset-x: 1rem;
        }

        // #7
        &:nth-child(7n+7) {
            --card-offset-y: 3rem;
            --card-offset-x: 10rem;
        }
    }

    @include respond-to(lg) {
        // placing cards
        // #1
        &:nth-child(7n+1) {
            --card-offset-y: -6rem;
            --card-offset-x: -15rem;
        }

        // #2
        &:nth-child(7n+2) {
            --card-offset-y: 4rem;
            --card-offset-x: 8rem;
        }

        // #3
        &:nth-child(7n+3) {
            --card-offset-y: -2rem;
            --card-offset-x: -11rem;
        }

        // #4
        &:nth-child(7n+4) {
            --card-offset-y: -8rem;
            --card-offset-x: 22rem;
        }

        // #5
        &:nth-child(7n+5) {
            --card-offset-y: 0.1rem;
            --card-offset-x: -4rem;
        }

        // #6
        &:nth-child(7n+6) {
            --card-offset-y: -4rem;
            --card-offset-x: 2rem;
        }

        // #7
        &:nth-child(7n+7) {
            --card-offset-y: -2rem;
            --card-offset-x: 14rem;
        }
    }
}

.slider__box {
    transform: translate3d(var(--card-offset-x), var(--card-offset-y), 0);
    pointer-events: all;

    @include respond-to(md) {
        transform: translate3d(calc(50vw + var(--card-w) * 1.3), 0, 0) rotate(-450deg);
        transition: transform $trans-time--s $trans-func--ease-in-out $trans-time--xs;

        &.is-active-next {
            transform: translate3d(calc(50vw + var(--card-w) / 4), -4rem, 0) rotate(-380deg);
            transition-delay: 0s;
        }

        &.is-active-before {
            transform: translate3d(var(--card-offset-x), var(--card-offset-y), 0);
            transition-delay: 0s;
        }
    }
}

// Hide scrollbars
.slider {
    * {
        scrollbar-color: transparent transparent; /* thumb and track color */
        scrollbar-width: 0px;
    }

    *::-webkit-scrollbar {
        width: 0;
    }

    *::-webkit-scrollbar-track {
        background: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background: transparent;
        border: none;
    }

    * {
        -ms-overflow-style: none;
    }
}

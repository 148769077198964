/*------------------------------------*\
  #TEXT-STYLES
\*------------------------------------*/

// Responsive Utilities for text layout formats
@each $name in map-keys($text-styles) {
    .text-style-#{$name} {
        @include responsive-selector-single(md, true) {
            @include text-style($name);
        }
    }
}
/*------------------------------------*\
  #card
\*------------------------------------*/

.card {
    padding: 1rem;
    background: var(--bg-fond);
    border-radius: var(--border-radius-m);
    box-shadow: var(--box-shadow-m);
    width: var(--card-w);
    max-width: 100%;
    min-height: calc(var(--card-w) / var(--card-ratio));
    display: flex;
    flex-direction: column;
    transform: translate3d(0,0,0) scale(0.98);
    transition: box-shadow $trans-time--s $trans-func--ease-in-out,
                transform $trans-time--s $trans-func--ease-in-out,
                background $trans-time--xs $trans-func--default;
    cursor: pointer;
    user-select: none; // prevent slection while dragging or clicking

    .is-active-before & {
        cursor: default;
    }

    cite {
        @include text-style('200');
        display: block; // Force line-height

        span {
            display: block;
        }
    }

    p:first-child {
        @include text-style('300');
    }

    @include hover('.slider__box', '.is-active') {
        background: var(--bg-highlight);
        box-shadow: var(--box-shadow-l);
        transform: translate3d(0,0,0);
    }

    @include respond-to(md) {
        // transition: transform $trans-time--s $trans-func--default;
        --offset-top: 0;
        --offset-left: 0;
        transform: translate3d(0, 0, 0);

        // show album title on rollover
        &.is-moving {
            transition: box-shadow $trans-time--s $trans-func--ease-in-out,
                        transform $trans-time--m $trans-func--default,
                        background $trans-time--xs $trans-func--default;
            transform: translate3d(var(--offset-left), var(--offset-top), 0) rotate(8deg) !important;
        }
    }

    @include respond-to(lg) {
        cite {
            @include text-style('400');
        }

        p {
            @include text-style('600');
        }

        p:first-child {
            @include text-style('400');
        }
    }
}

.card__body {
    padding-bottom: 1rem;

    @include respond-to(lg) {
        padding-bottom: 2rem;

    }
}

.card__text {
    margin-left: 1rem;
    transform: var(--card-body-transform);

    @include respond-to(lg) {
        margin-left: 2rem;
        margin-right: 1rem;
    }
}

.card__tags {
    margin-top: auto; // push to bottom
    display: flex;
    flex-wrap: wrap;

    .tag {
        margin-left: -1px;
        margin-top: -1px;
        transition: all $trans-time--xs $trans-func--default;

        @include hover('.slider__box', '.is-active') {
            color: var(--text-tag-highlight);
            border-color: var(--border-highlight);
        }
    }
}


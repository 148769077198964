/*------------------------------------*\
  #CTA
\*------------------------------------*/

// Default button
//

$_btn-h: 2rem;
$_btn-p: 1rem;

.cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    // Reset default button styles
    font: inherit;
    text-align: center;
    cursor: pointer;
    color: inherit;
    outline: none;
    border: none;
    background: none;
    appearance: none;

    --animation-duration: 0.2s;

    @include text-style('400');

    .icon {
        @include cover-box();
        width: 100%;
        height: 100%;
    }
}

.cta__inner {
    // Animation wrapper
    position: relative;
}

.cta__title {
    transform: var(--cta-transform);
    white-space: nowrap;
    display: block;
    animation: cta-hover var(--animation-duration) $trans-func--ease-in-out both;
    // transform-origin: bottom left;

    @include hover('.cta') {
        animation: cta-hide-hover var(--animation-duration) $trans-func--ease-in-out both;
    }
}

// hover
.cta__hover {
    @include cover-box();
    transform: var(--cta-transform);
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: cta-hide-hover var(--animation-duration) $trans-func--ease-in-out both;
    // transform-origin: bottom left;

    @include hover('.cta') {
        // visibility: visible;
        animation: cta-hover var(--animation-duration) $trans-func--ease-in-out both;
    }
}

.cta--l {
    @include respond-to(md) {
        @include text-style('600');

        svg {
            stroke-width: map-get($stroke-width, m);
        }
    }
}

// Animate CTA with intersection observer
.cta--animated {
    .cta__inner {
        visibility: hidden;

        .intersect-infullview--before & {
            visibility: visible;
            animation: cta-hide var(--animation-duration) $trans-func--ease-in-out both;
        }

        .intersect-inview--full & {
            visibility: visible;
            animation: cta-show var(--animation-duration) $trans-func--ease-in-out var(--animation-delay) both;
        }
    }
}

.cta--highlight {
    color: var(--text-highlight);
}

.cta--circle-1 {
    height: 7.5rem;
    width: 10.5rem;

    @include respond-to(md) {
        height: 11rem;
        width: 16rem;
    }
}

.cta--line-1,
.cta--line-3 {
    margin: 4rem 0 0 9.4rem;

    .icon {
        width: 11rem;
        height: 5rem;
        right: 3rem;
        bottom: 1rem;
        top: auto;
        left: auto;
    }

    @include respond-to(md) {
        margin: 5.4rem 0 0 18rem;

        .icon {
            width: 16rem;
            height: 6rem;
            right: 5.4rem;
            bottom: 2.4rem;
        }
    }
}

.cta--line-2 {
    margin: 4rem 0 0 7.5rem;

    .icon {
        width: 8rem;
        height: 5rem;
        right: 5.4rem;
        bottom: 1rem;
        top: auto;
        left: auto;
    }

    @include respond-to(md) {
        margin: 5.4rem 0 0 12rem;

        .icon {
            width: 9rem;
            height: 6rem;
            right: 6rem;
            bottom: 1rem;
        }
    }
}

.cta--line-3 {
    margin: 3rem 0 0 9.4rem;

    .icon {
        right: 4rem;
    }

    @include respond-to(md) {
        margin: 4.4rem 0 0 17rem;

        .icon {
            right: 7rem;
            bottom: 2rem;
        }
    }
}

/*------------------------------------*\
  #contact
\*------------------------------------*/

.contact {
    position: fixed;
    bottom: 0;
    right: 0;
    display: block;
    padding: var(--body-p);
    z-index: map-get($z-index, contact);
    @include text-color('highlight');
    transition: transform $trans-time--s $trans-func--ease-in-out 0.1s,
                opacity $trans-time--s $trans-func--ease-in-out 0.1s;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 1000%, 0) scaleY(50);

    .is-scrolled-some:not(.has-contact) & {
        opacity: 1;
        transform: none;
        pointer-events: all;
    }

    @include respond-to(lg) {
        display: none;
    }
}

/*------------------------------------*\
  #main
\*------------------------------------*/

.main {
    .show-new-section & {
        animation: show-new-section 0.5s $trans-func--ease-out both;
    }

    // Add cover for logo and nav on mobile
    &:after {
        display: block;
        content: '';
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        height: 6rem;
        pointer-events: none;
        background: var(--bg-default);
        opacity: 0;
        transition: opacity 1s $trans-func--ease-in-out;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

        .is-scrolled-some:not(.has-contact) & {
            opacity: 0.9;
        }
    }

    @include respond-to(lg) {
        &:after {
            display: none;
        }
    }
}


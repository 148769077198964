/*------------------------------------*\
  #Method
\*------------------------------------*/

.method {
    position: relative;
    margin-right: var(--body-p);
    margin-left: var(--body-p);

    @include respond-to(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
    }
}

.method__head {
    position: sticky;
    top: 0;
    z-index: 1;

    // Add cover for header on mobile
    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 6rem;
        pointer-events: none;
        background: var(--bg-default);
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    h2 {
        @include text-style('500');
        color: var(--text-highlight);
        padding-top: 0.4rem;
        position: relative;
    }

    @include respond-to(md) {
        top: 45vh;

        &:before {
            display: none;
        }

        h2 {
            @include text-style('600');
            text-align: center;
            padding-bottom: 8rem;
        }
    }
}

.method__body {
    padding-left: var(--text-indent);
    margin-top: 1.4rem;

    @include respond-to(md) {
        padding-left: 0;
        margin-top: var(--size-1300);
    }
}

.method__item {
    position: relative;
}

.method__icon {
    display: none;

    @include respond-to(lg) {
        display: block;
        position: absolute;
    }
}

.method__icon-1 {
    left: -6.4rem;
    top: 19rem;
}

.method__icon-2 {
    --animation-delay: 0.15s;
    right: -5rem;
    top: 17rem;
}

.method__cta {
    display: none;

    @include respond-to(lg) {
        --animation-delay: 0.5s; // wait some time before animation triggers here

        position: absolute;
        top: 0;
        left: -28rem;
        display: block;
    }
}
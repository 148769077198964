/*------------------------------------*\
  #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: inherit;
    transition: color $trans-time--s $trans-func--default;

    &:hover {
        // @include hover() { // TODO: add browser sniffing and add hover vor non-touch only
        @include text-color('highlight');
    }
}